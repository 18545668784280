// 合同列表--计算过期时间
export function computerDate(endDate, contractStatus) {
  const nowDate = new Date();
  const endTime = this.$moment(endDate).valueOf();
  const nowTime = this.$moment(nowDate).valueOf();

  //  合同有效性
  if (contractStatus == 3 || contractStatus == 4) {
    return "-";
  } else {
    // 计算剩余期限
    if (endTime <= nowTime) {
      return 0;
    } else {
      const remainder = (endTime - nowTime) / (1000 * 60 * 60 * 24);
      return Math.ceil(remainder);
    }
  }
}

// 合同列表 -- 过滤合同相关pdf
export function formatPdfPath(pdfPath, pdfName) {
  let newPath = null;
  if (pdfPath !== null && pdfPath !== "") {
    const pdfPathArr = pdfPath.split("_");
    const timestamp = pdfPathArr[pdfPathArr.length - 1].match(/(\S*).pdf/)[1];
    const timeFormat = this.$moment(Number(timestamp)).format("YYYY-MM-DD"); // HH:mm:ss
    if (pdfName === "") {
      newPath = pdfPathArr[0] + "_" + timeFormat + ".pdf";
    } else {
      newPath = pdfName + "_" + timeFormat + ".pdf";
    }
  } else {
    newPath = null;
  }
  return newPath;
}
