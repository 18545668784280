// 合同状态值
export const optionsLeaseStatus = [
  { value: 1, label: "存续" },
  { value: 2, label: "起诉中" },
  { value: 3, label: "已结束" },
  { value: 4, label: "已结束(36)" },
];

// 合同审核状态值
export const auditOptionsStatus = [
  { value: 0, label: "初始态" },
  { value: 1, label: "待审核" },
  { value: 2, label: "待修改" },
  { value: 3, label: "待发布" },
  { value: 4, label: "已发布" },
];
