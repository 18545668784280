import { request } from '@/utils/request'

// 生成电子合同
export function addContract(data, id) {
    return request({
        url: `/api/manage/contract/genContractPDF/${id}`,
        method: 'post',
        data
    })
}


// 查询电子合同
export function getContract(id) {
    return request({
        url: `/api/manage/contract/getContractSupplemenById/${id}`,
        method: 'get',
    })
}

// 更新 电子合同
export function updateContractPDF(data, id) {
    return request({
        url: `/api/manage/contract/updateContractPDF/${id}`,
        method: 'post',
        data
    })
}

// 下载电子合同
export function downContractPDF(id) {
    return request({
        url: `/api/manage/contract/downloadContractPDF/${id}`,
        method: 'post',
        responseType: 'blob',//服务器返回的数据类型
        timeout: 500 * 1000,
    })
}

// 甲方公司抬头--电子合同
export function getContractHeaderInf(id) {
    return request({
        url: `/api/manage/company/getById/${id}`,
        method: 'get',
    })
}

//查询还款计划表
export function getRepanmentPlanList(data) {
    return request({
        url: `/api/manage/rePaymentPlan/getByContractId`,
        method: 'post',
        data
    })
}

//生成还款计划表
export function addRepanmentPlanList(id) {
    return request({
        url: `/api/manage/rePaymentPlan/genRePaymentPlanByContractId/${id}`,
        method: 'get'
    })
}

//还款计划表--批注
export function setRepanmentPlanAnnotate(data, id) {
    return request({
        url: `/api/manage/contract/setPlanAnnotate/${id}`,
        method: 'post',
        data
    })
}

// 更改还款日
export function updateRepaymentDate(data) {
    return request({
        url: `/api/manage/rePaymentPlan/updateRepaymentDate`,
        method: 'post',
        data
    })
}

// 设置合同PDF批注
export function setAnnotation(data, id) {
    return request({
        url: `/api/manage/contract/setAnnotation/${id}`,
        method: 'post',
        data
    })
}
